<template>
  <div class="main">
    <div class="content">
      <div class="title">
        <p>资讯中心</p>
        <span @click="$router.push('/countyCityZone/newsPage?ID=' + $route.query.ID)">查看更多 <i class="el-icon-arrow-right"></i></span>
      </div>
      <div class="tabs">
        <p class="tab" :class="{'active':  activeIndex === '1'}" @click="getNewsList('1')">新闻资讯</p>
        <p class="tab" :class="{'active':  activeIndex === '2'}" @click="getNewsList('2')">通知公告</p>
      </div>
      <div class="news">
        <div class="news-img">
          <img v-if="newsHover" :src="newsHover.image" alt="">
        </div>
        <div class="news-list">
          <ul class="news-list-ul">
            <li class="news-list-li" v-for="(item, index) in newsList" :key="index" @mouseover="newsHover=item">
              <p class="news-title">{{ item.name }}</p>
              <p class="news-time">{{ item.publish }}</p>
            </li>
          </ul>
        </div>
      </div>
      <div class="title">
        <p>课程专区</p>
        <span @click="$router.push('/countyCityZone/coursePage?ID=' + $route.query.ID)">查看更多 <i class="el-icon-arrow-right"></i></span>
      </div>
      <div class="course">
        <div class="tabs">
          <p v-for="(item, index) in courseModuleList" :key="index" class="tab" :class="{'active':  item.id === '1'}" @click="getCourseList(item.id)">{{ item.name }}</p>
        </div>
        <div v-if="courseList.length > 0" class="course-list">
          <ul>
            <li v-for="(item, index) in courseList" :key="index">
              <div class="course-img">
                <img :src="item.image" alt="">
              </div>
              <div class="course-info">
                <p class="course-time">{{ item.created_at }}</p>
                <p class="course-title">{{ item.name }}</p>
                <p class="course-desc">{{ item.intro }}</p>
                <!-- <p class="course-teacher"> 
                  <img src="@/assets/laoshi.png" alt=""> 
                  <span>主讲老师：</span>
                  <span class="teacher-name">{{ item.intro }}</span>
                </p> -->
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'advicePage',
  data () {
    return {
      ID: '',
      activeIndex: '1',
      tabIndex: '1',
      newsList: [],
      newsHover: {},
      courseList: [],
      courseModuleList: []
    }
  },
  created () {
    this.ID = this.$route.query.ID
    this.getNewsList('1')
    this.getCourseModuleList()
  },
  methods: {
    getNewsList (index) {
      this.activeIndex = index;
      this.newsList = [];
      this.newsHover = {};
      this.$axios.post(`/v1/qn/newsList`, { area_id: this.ID, type: this.activeIndex }).then((res) => {
          if (res.code == 0) {
            this.newsList = res.data.data;
            this.newsHover = res.data.data[0];
          }
        });
    },
    getCourseModuleList() {
      this.$axios.post(`/v1/qn/courseModuleList`, { area_id: this.ID }).then((res) => {
        if (res.code == 0) {
          this.courseModuleList = res.data.data;
          if (res.data.data.length > 0) {
            this.getCourseList(res.data.data[0].id)
          }
        }
      });
    },
    getCourseList (id) {
      this.tabIndex = id;
      this.courseList = [];
      this.$axios.post(`/v1/qn/courseList`, { area_id: this.ID, module_id: id }).then((res) => {
        if (res.code == 0) {
          this.courseList = res.data.data;
        }
      });
    },
  }
}
</script>
<style lang="scss" scoped>
.main {
  background-color: #F7FAFF;
  .content {
    width: 1200px;
    margin: 0 auto;
    padding-top: 50px;
    padding-bottom: 120px;
    background-color: #FFFFFF;
    .title {
      display: flex;
      align-items: center;
      justify-content: center;
      position: relative;
      p {
        font-family: AlimamaShuHeiTi;
        font-weight: bold;
        font-size: 40px;
        color: #366CEC;
        line-height: 49px;
      }
      span {
        position: absolute;
        right: 30px;
        top: 50%;
        transform: translateY(-50%);
        display: block;
        font-weight: normal;
        font-size: 16px;
        color: #666666;
        line-height: 22px;
        text-align: left;
        cursor: pointer;
      }
    }
    .tabs {
      display: flex;
      justify-content: center;
      margin-top: 40px;
      .tab {
        height: 50px;
        padding: 0px 23px;
        background: #F0F6FF;
        border-radius: 5px 5px 5px 5px;
        font-weight: 500;
        font-size: 22px;
        color: #7EA0F0;
        line-height: 50px;
        text-align: center;
        margin: 0 25px;
      }
      .active {
        cursor: pointer;
        background: #366CEC;
        color: #FFFFFF;
      }
    }
    .news {
      box-sizing: border-box;
      width: 1140px;
      margin: 0 auto;
      margin-top: 40px;
      background: #FFFFFF;
      box-shadow: 0px 0px 20px 1px rgba(119,156,255,0.16);
      border-radius: 10px 10px 10px 10px;
      padding: 14px;
      display: flex;
      margin-bottom: 100px;
      .news-img {
        width: 605px;
        height: 383px;
        background: #2973F0;
        border-radius: 10px 10px 10px 10px;
        img {
          width: 100%;
          height: 100%;
          border-radius: 10px 10px 10px 10px;
        }
      }
      .news-list {
        margin-left: 15px;
        .news-list-ul {
          .news-list-li {
            width: 482px;
            box-sizing: border-box;
            padding: 18px 35px 20px 19px;
            border-bottom: 1px solid #70707019;
            .news-title {
              font-weight: bold;
              font-size: 18px;
              color: #333333;
              line-height: 25px;
              text-align: left;
              display: -webkit-box;
              -webkit-box-orient: vertical;
              -webkit-line-clamp: 1;
              overflow: hidden;
            }
            .news-time {
              font-weight: 500;
              font-size: 13px;
              color: #666666;
              line-height: 18px;
              text-align: left;
              margin-top: 12px;
            }
            &:last-child {
              border-bottom: 1px solid #FFFFFF;
            }
            &:hover {
              background: #F0F6FF;
              border-radius: 10px 10px 10px 10px;
              border-bottom: 1px solid #F0F6FF;
              .news-title {
                color: #366CEC;
              }
            }
          }
        }
      }
    }
    .course {
      .course-list {
        margin-top: 10px;
        ul {
          display: flex;
          flex-wrap: wrap;
          li {
            margin: 20px 19px;
            .course-img {
              width: 355px;
              height: 200px;
              border-radius: 10px 10px 0px 0px;
              img {
                width: 100%;
                height: 100%;
                border-radius: 10px 10px 0px 0px;
              }
            }
            .course-info {
              width: 355px;
              // height: 178px;
              padding: 20px;
              box-sizing: border-box;
              background: #FFFFFF;
              box-shadow: 0px 10px 18px 1px rgba(180,180,180,0.16);
              border-radius: 0px 0px 10px 10px;
              .course-time {
                font-weight: 500;
                font-size: 14px;
                color: #666666;
                line-height: 20px;
                text-align: left;
              }
              .course-title {
                font-weight: bold;
                font-size: 18px;
                color: #333333;
                line-height: 25px;
                text-align: left;
                margin-top: 12px;
                display: -webkit-box;
                -webkit-box-orient: vertical;
                -webkit-line-clamp: 1;
                overflow: hidden;
              }
              .course-desc {
                font-weight: 500;
                font-size: 14px;
                color: #666666;
                line-height: 22px;
                text-align: left;
                margin-top: 12px;
                display: -webkit-box;
                -webkit-box-orient: vertical;
                -webkit-line-clamp: 2;
                overflow: hidden;
              }
              .course-teacher {
                display: flex;
                align-items: center;
                margin-top: 12px;
                img {
                  width: 16px;
                  height: 16px;
                }
                span {
                  font-weight: bold;
                  font-size: 12px;
                  line-height: 23px;
                  color: #666666;
                  text-align: left;
                }
                .teacher-name {
                  color: #366CEC;
                  padding-left: 10px;
                }
              }
            }
          }
        }
      }
    }
  }
}
</style>